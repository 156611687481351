<template>
  <div>
    <cardTitleCom cardTitle="裱花APP开通">
      <template slot="cardContent">
        <div class="head">
          <div class="head-logo" :style="`background-image:url(${require('@/assets/images/bh-log.png')});`"></div>
          <div>
            <div style="font-size: 14px; line-height: 28px">
              <div style="font-size: 16px; font-weight: bold">裱花间APP</div>
              <div>
                裱花间
                APP是一款专为中央裱花间/工厂设计的智能管理系统，能够实时监控不同类型的业务订单的接单、生产、配送和签收过程。
              </div>
              <!-- <div>价格：{{ price || '--' }}</div> -->
            </div>
            <div class="buttons">
              <div class="button" @click="goBuy">
                {{ price }} {{ isBuy == 'buy' ? '立即开通' : '立即续费' }}
              </div>
              <el-link type="primary" class="text-button" @click="goList">查询订单列表></el-link>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <div class="head cardContent2 el-card is-always-shadow">
      <div class="text-view">
        <div style="font-size: 16px; font-weight: bold">产品介绍</div>
        <div class="text-p">
          随着电商的发展和门店成本的上升，烘焙连锁企业开始采用中央裱花间模式，提高效率和
          降低人力成本。裱花间
          APP是一款专为中央裱花间/工厂设计的智能管理系统，能够实时监控
          不同类型的业务订单的接单、生产、配送和签收过程。
        </div>
        <div class="text-p">
          通过裱花间
          APP，烘焙连锁企业可以实现裱花制作集中化、信息化，提升客户满意度。
          客户下单后，订单信息自动传送到裱花间平板并打印小票，裱花师根据订单信息及图片样式
          制作蛋糕，订单进度以及最新状态可实时同步到门店及C端用户，增强数据共享和协同优化。
        </div>
      </div>
      <div class="logo2" :style="`background-image:url(${require('@/assets/images/bh-panel.png')});`"></div>
    </div>
  </div>
</template>
<script>
import { getMinPay } from '@/utils'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  getOrderDetail,
  getTradeProductToRenew
} from '@/api/produce/decoration/index.js'
export default {
  name: 'goBuyIndex',
  components: { cardTitleCom },
  data() {
    return {
      renewObj: {},
      isBuy: 'buy',
      price: '---'
    }
  },
  mounted() {
    // 获取价格
    this.getOrder()
  },
  methods: {
    async getOrder() {
      try {
        const { data } = await this.getDicts('product_years_package_mode')
        const yearsData = data || []
        //判断是新增还是续费
        const res1 = await getTradeProductToRenew()
        if (res1.type == 'renew') {
          this.renewObj = res1.data
        }
        this.isBuy = res1.type
        const res = await getOrderDetail()
        console.log('res', res)
        const res2 = getMinPay(res.data, res1.type, yearsData)
        console.log('res2', res2)
        this.price = `￥${res2.pay}/${res2.text
          } 起`
      } catch (error) {
        console.log('priceArr error ', error)
      }
    },

    goBuy() {
      if (this.isBuy == 'buy') {
        this.$emit('update:active', 2)
        this.$emit('resume', {})
      } else {
        let option = {
          productId: this.renewObj.productId,
          productBookDetailIds: this.renewObj.productBookDetailId ? [this.renewObj.productBookDetailId] : [],
          tenantId: this.$store.state.user.userinfo.tenantId
        }
        this.$emit('activeInfo', option)
        this.$emit('resume', {})
      }
    },
    goList() {
      this.$emit('update:active', 3)
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  ::v-deep .cardContent {
    padding: 10px;
  }
  .cardContent2 {
    padding: 110px 70px;
    height: calc(100vh - 350px);
    min-height: 400px;
    .text-view {
      margin-right: 60px;
      flex: 1;
      .text-p {
        text-indent: 4ch;
        padding-top: 15px;
        font-size: 14px;
        line-height: 30px;
      }
    }
    .logo2 {
      margin-left: 60px;
      flex: 1;
      width: 542px;
      height: 400px;
      background-size: 100% 100%;
    }
  }
  .head {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .head-logo {
      height: 100px;
      border-radius: 5px;
      overflow: hidden;
      width: 100px;
      margin-right: 10px;
      margin-left: 10px;
      background-size: 100% 100%;
    }
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;

    .button {
      width: 220px;
      height: 39px;
      line-height: 39px;
      text-align: center;
      background: #f2f2f2;
      border-radius: 24px;
      font-size: 18px;
      color: #ffffff;
      background-color: #ff9e40;
      overflow: hidden;
      cursor: pointer;
    }

    .text-button {
      margin-left: 20px;
      font-size: 14px;
      height: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>
