var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "div",
        { staticClass: "btnTOP" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.getQuit } },
            [_vm._v("退出")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.cookPrintForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "方案编号",
                            prop: "decorationCaseNo",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: { disabled: "", placeholder: "自动生成" },
                            model: {
                              value: _vm.cookPrintForm.decorationCaseNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cookPrintForm,
                                  "decorationCaseNo",
                                  $$v
                                )
                              },
                              expression: "cookPrintForm.decorationCaseNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "方案名称",
                            prop: "decorationCaseName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: { placeholder: "方案名称" },
                            model: {
                              value: _vm.cookPrintForm.decorationCaseName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cookPrintForm,
                                  "decorationCaseName",
                                  $$v
                                )
                              },
                              expression: "cookPrintForm.decorationCaseName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            staticClass: "inputRemark",
                            attrs: {
                              type: "textarea",
                              placeholder: "备注长度介于 1 和 80 字符之间",
                              maxlength: "80",
                              autosize: { minRows: 1, maxRows: 5 },
                            },
                            model: {
                              value: _vm.cookPrintForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.cookPrintForm, "remark", $$v)
                              },
                              expression: "cookPrintForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用门店" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "适用门店", prop: "shopRangeType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.cookPrintForm.shopRangeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cookPrintForm,
                                    "shopRangeType",
                                    $$v
                                  )
                                },
                                expression: "cookPrintForm.shopRangeType",
                              },
                            },
                            _vm._l(
                              _vm.dict.type.fill_shop_range_type,
                              function (dict) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: dict.value,
                                    attrs: { label: parseInt(dict.value) },
                                  },
                                  [_vm._v(_vm._s(dict.label))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.cookPrintForm.shopRangeType == 1 ||
                                _vm.cookPrintForm.shopRangeType == 2,
                              expression:
                                "\n                cookPrintForm.shopRangeType == 1 ||\n                cookPrintForm.shopRangeType == 2\n              ",
                            },
                          ],
                          staticClass: "tableContent",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "marB10",
                              attrs: { size: "mini" },
                              on: { click: _vm.openSelectShop },
                            },
                            [_vm._v("选择门店")]
                          ),
                          _c("comTable", {
                            staticClass: "marB10",
                            attrs: {
                              columnLabel: _vm.shopTableLable,
                              tableData:
                                _vm.cookPrintForm.decorationCaseShopReqs,
                            },
                            on: {
                              delTableRow: function ($event) {
                                return _vm.delTableRow($event, "shop")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用商品" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _vm.rangeGoods
                    ? _c(
                        "div",
                        { staticClass: "rangeVip" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "适用商品",
                                prop: "goodsRangeType",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.cookPrintForm.goodsRangeType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cookPrintForm,
                                        "goodsRangeType",
                                        $$v
                                      )
                                    },
                                    expression: "cookPrintForm.goodsRangeType",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.vip_goods_range_type,
                                  function (dict) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: dict.value,
                                        attrs: { label: Number(dict.value) },
                                      },
                                      [_vm._v(_vm._s(dict.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.cookPrintForm.goodsRangeType === 2 ||
                                    _vm.cookPrintForm.goodsRangeType === 1,
                                  expression:
                                    "\n                cookPrintForm.goodsRangeType === 2 ||\n                cookPrintForm.goodsRangeType === 1\n              ",
                                },
                              ],
                              staticClass: "rangeVip",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    size: "mini",
                                  },
                                  on: { click: _vm.getGoodsFrame },
                                },
                                [_vm._v("指定商品")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    size: "mini",
                                  },
                                  on: { click: _vm.getOpenBrand },
                                },
                                [_vm._v("指定品牌")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    size: "mini",
                                  },
                                  on: { click: _vm.getOpenCategory },
                                },
                                [_vm._v("指定类别")]
                              ),
                              _c(
                                "div",
                                { staticClass: "marT10" },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loadingBrand,
                                          expression: "loadingBrand",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "tooltip-effect": "dark",
                                        data: _vm.cookPrintForm.goodsItem.concat(
                                          _vm.cookPrintForm.goodsBrand,
                                          _vm.cookPrintForm.goodsCategory
                                        ),
                                        border: "",
                                        "max-height": "400",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "序号",
                                          align: "center",
                                          prop: "userId",
                                          width: "80",
                                          type: "index",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "操作",
                                          width: "120",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "mini",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.delTableRow(
                                                            row,
                                                            "goods"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-remove operateDel",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3735386037
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "类型",
                                          align: "center",
                                          prop: "lineType",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("dict-tag", {
                                                    attrs: {
                                                      options:
                                                        _vm.dict.type
                                                          .vip_applicable_commodities_appoint_type,
                                                      value: scope.row.lineType,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1794384631
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "编号",
                                          align: "center",
                                          prop: "lineNo",
                                          width: "180",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "名称",
                                          align: "center",
                                          prop: "lineName",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "规格",
                                          align: "center",
                                          prop: "goodsSpec",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "单位",
                                          align: "center",
                                          prop: "unitName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("selectShop", {
        attrs: { OpenShop: _vm.OpenShop },
        on: {
          "update:OpenShop": function ($event) {
            _vm.OpenShop = $event
          },
          "update:open-shop": function ($event) {
            _vm.OpenShop = $event
          },
          getShopData: function ($event) {
            return _vm.getPitchData($event, "shop")
          },
        },
      }),
      _c("SelectGoods", {
        attrs: { OpenGoods: _vm.OpenGoods },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.OpenGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.OpenGoods = $event
          },
          getGoodsIds: function ($event) {
            return _vm.getPitchData($event, "goodsItem")
          },
        },
      }),
      _c("SelectBrand", {
        attrs: { OpenBrand: _vm.OpenBrand },
        on: {
          "update:OpenBrand": function ($event) {
            _vm.OpenBrand = $event
          },
          "update:open-brand": function ($event) {
            _vm.OpenBrand = $event
          },
          brandList: function ($event) {
            return _vm.getPitchData($event, "goodsBrand")
          },
        },
      }),
      _c("selectCategory", {
        attrs: { OpenCategory: _vm.OpenCategory },
        on: {
          "update:OpenCategory": function ($event) {
            _vm.OpenCategory = $event
          },
          "update:open-category": function ($event) {
            _vm.OpenCategory = $event
          },
          getCategoryData: function ($event) {
            return _vm.getPitchData($event, "goodsCategory")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }