<template>
  <div class="wrap" v-loading="loading">
    <!-- 顶部按钮 -->
    <div class="btnTOP">
      <el-button type="primary" size="mini" @click="submitForm">保存</el-button>
      <el-button size="small" @click="getQuit">退出</el-button>
    </div>
    <el-form ref="form" :model="cookPrintForm" :rules="rules" label-width="100px">
      <cardTitleCom cardTitle="基本信息">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-f">
              <el-form-item label="方案编号" prop="decorationCaseNo">
                <el-input
                  disabled
                  class="inputWidth"
                  v-model="cookPrintForm.decorationCaseNo"
                  placeholder="自动生成"
                ></el-input>
              </el-form-item>
              <el-form-item label="方案名称" prop="decorationCaseName">
                <el-input
                  class="inputWidth"
                  v-model="cookPrintForm.decorationCaseName"
                  placeholder="方案名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input
                  class="inputRemark"
                  v-model="cookPrintForm.remark"
                  type="textarea"
                  placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>

      <cardTitleCom cardTitle="适用门店">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 实用门店 -->
            <div>
              <el-form-item label="适用门店" prop="shopRangeType">
                <el-radio-group v-model="cookPrintForm.shopRangeType">
                  <el-radio
                    v-for="dict in dict.type.fill_shop_range_type"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                  >{{ dict.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 非全部门店 -->
              <div
                class="tableContent"
                v-show="
                  cookPrintForm.shopRangeType == 1 ||
                  cookPrintForm.shopRangeType == 2
                "
              >
                <el-button size="mini" class="marB10" @click="openSelectShop">选择门店</el-button>
                <!-- 门店表格 -->
                <comTable
                  class="marB10"
                  :columnLabel="shopTableLable"
                  :tableData="cookPrintForm.decorationCaseShopReqs"
                  @delTableRow="delTableRow($event, 'shop')"
                ></comTable>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用商品">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="rangeVip" v-if="rangeGoods">
              <el-form-item label="适用商品" prop="goodsRangeType">
                <!-- :disabled="disabled" -->
                <el-radio-group v-model="cookPrintForm.goodsRangeType">
                  <el-radio
                    v-for="dict in dict.type.vip_goods_range_type"
                    :key="dict.value"
                    :label="Number(dict.value)"
                  >{{ dict.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 指定适用商品 -->
              <div
                class="rangeVip"
                v-show="
                  cookPrintForm.goodsRangeType === 2 ||
                  cookPrintForm.goodsRangeType === 1
                "
              >
                <el-button @click="getGoodsFrame" :disabled="disabled" size="mini">指定商品</el-button>
                <el-button @click="getOpenBrand" :disabled="disabled" size="mini">指定品牌</el-button>
                <el-button @click="getOpenCategory" :disabled="disabled" size="mini">指定类别</el-button>
                <!-- 指定适用商品表格 -->
                <div class="marT10">
                  <el-table
                    tooltip-effect="dark"
                    style="width: 100%"
                    :data="[
                      ...cookPrintForm.goodsItem,
                      ...cookPrintForm.goodsBrand,
                      ...cookPrintForm.goodsCategory,
                    ]"
                    border
                    max-height="400"
                    v-loading="loadingBrand"
                  >
                    <el-table-column
                      label="序号"
                      align="center"
                      prop="userId"
                      width="80"
                      type="index"
                    />
                    <el-table-column align="center" label="操作" width="120">
                      <template #default="{ row }">
                        <el-button
                          @click.native.prevent="delTableRow(row, 'goods')"
                          type="text"
                          size="mini"
                        >
                          <i class="el-icon-remove operateDel" />
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="类型" align="center" prop="lineType">
                      <template slot-scope="scope">
                        <dict-tag
                          :options="
                            dict.type.vip_applicable_commodities_appoint_type
                          "
                          :value="scope.row.lineType"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column label="编号" align="center" prop="lineNo" width="180"></el-table-column>
                    <el-table-column label="名称" align="center" prop="lineName" />
                    <el-table-column label="规格" align="center" prop="goodsSpec" />
                    <el-table-column label="单位" align="center" prop="unitName" />
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <!-- 适用商品 -->
    </el-form>

    <!-- 门店 -->
    <selectShop :OpenShop.sync="OpenShop" @getShopData="getPitchData($event, 'shop')" />
    <!-- 选择商品信息框 -->
    <SelectGoods :OpenGoods.sync="OpenGoods" @getGoodsIds="getPitchData($event, 'goodsItem')" />
    <!-- 品牌 -->
    <SelectBrand :OpenBrand.sync="OpenBrand" @brandList="getPitchData($event, 'goodsBrand')" />
    <!-- 商品类别 -->
    <selectCategory
      :OpenCategory.sync="OpenCategory"
      @getCategoryData="getPitchData($event, 'goodsCategory')"
    />
  </div>
</template>

<script>
import { getNewBillNo } from "@/utils/numFormatting"; //获取单据编号
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import SelectGoods from "@/views/components/SelectGoods/SelectGoods.vue"; //商品
import selectCategory from "@/views/marketing/components/selectCategory.vue"; //商品类别
import selectBrand from "@/views/components/selectBrand"; //品牌组件
import shopInfo from "@/views/components/shopInfo"; //门店
import selectShop from "@/views/marketing/components/selectShop.vue"; //门店组件
import SelectBrand from "@/views/components/selectBrand"; //门店资料
import comTable from "@/views/components/com-table"; //表格
import {
  saveCase,
  getCaseDetail,
  updateCase
} from "@/api/produce/decoration/index.js"; //厨打商品
export default {
  name: "goodsCaseDetail",
  dicts: [
    "fill_shop_range_type", //门店范围
    "vip_goods_range_type", //商品范围
    "vip_applicable_commodities_appoint_type" //商品表格中类型
  ],
  components: {
    comTable,
    cardTitleCom,
    SelectGoods,
    shopInfo,
    SelectBrand,
    selectCategory,
    selectBrand,
    selectShop
  },
  data() {
    return {
      //选择门店开关
      OpenShop: false,
      //门店表格字段
      shopTableLable: [
        { label: "编号", prop: "shopNo" },
        { label: "名称", prop: "shopName" }
      ],
      disabled: false,
      //大盒子遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      rangeGoods: true, //适用商品
      OpenGoods: false, //选择商品信息开关
      loadingBrand: false, //商品品牌加载
      OpenCategory: false, //选择类别开关
      OpenBrand: false, //选择品牌开关

      //厨打方案表单
      cookPrintForm: {
        decorationCaseId:"",
        decorationCaseName: "", //方案名称
        decorationCaseNo: "", //方案编号
        shopRangeType: 0, //适用门店 0,1,2
        goodsRangeType: 0, //适用商品  0,1,2
        decorationCaseGoodsReqs: [], //商品方案适用商品新增对象 1:商品;2:品牌;3:类别
        produceDecorationCaseShopReqs: [], // 门店适用范围
        isStop: 1,

        goodsCategory: [],
        goodsBrand: [],
        goodsItem: []
      },
      //plus会员方案表单校验
      rules: {
        decorationCaseNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"]
          }
        ],
        decorationCaseName: [
          {
            required: true,
            message: "方案名称不能为空",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter(to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async vm => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      try {
        // 访问组件实例 vm 中的数据和方法
        if (vm.$route.query.type === "Update") {
          if (vm.$route.query.decorationCaseId) {
            //获取单据详情
            const { data } = await getCaseDetail({
              decorationCaseId: vm.$route.query.decorationCaseId
            });
            //单据赋值
            data.goodsItem = [];
            data.goodsBrand = [];
            data.goodsCategory = [];
            data.shopRangeType = Number(data.shopRangeType);
            data.goodsRangeType = Number(data.goodsRangeType);
            data.decorationCaseGoodsReqs =
              data.decorationCaseGoodsResp;
            data.decorationCaseShopReqs =
              data.decorationCaseShopResp;
            data.decorationCaseGoodsReqs?.forEach?.(x => {
              // 1:商品;2:品牌;3:类别
              const config = {
                1: "goodsItem",
                2: "goodsBrand",
                3: "goodsCategory"
              };
              if (config[x.lineType]) {
                data[config[x.lineType]].push(x);
              }
            });
            Object.keys(vm.cookPrintForm).forEach(key => {
              if (data[key]) {
                vm.cookPrintForm[key] = data[key];
              }
            });
            console.log("vm.cookPrintForm", vm.cookPrintForm, data);
            //原始单据赋值
            vm.oldForm = JSON.parse(JSON.stringify(vm.cookPrintForm));
          }
        } else {
          //判断是否有以前新增时保存的数据
          if (JSON.stringify(vm.oldForm) != "{}") {
            vm.cookPrintForm = vm.oldForm;
          }
          vm.oldForm = JSON.parse(JSON.stringify(vm.cookPrintForm));
          vm.getNewBillNo(vm.cookPrintForm, "decorationCaseNo", 170301);
        }
      } catch (error) {
        console.log("vm.error", error);
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.cookPrintForm.decorationCaseId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.cookPrintForm));
    }
    next();
  },
  methods: {
    //新增时获取单据编号
    getNewBillNo,
    /** 提交按钮 */
    async submitForm() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          console.log("thuis.", this.$route);
          let isUpdate = this.cookPrintForm.decorationCaseId;
          try {
            this.cookPrintForm.decorationCaseShopReqs =
              Number(this.cookPrintForm.shopRangeType) !== 0
                ? this.cookPrintForm?.decorationCaseShopReqs?.map?.(
                    ({ shopId }) => ({ shopId })
                  )
                : [];
            this.cookPrintForm.decorationCaseGoodsReqs =
              Number(this.cookPrintForm.goodsRangeType) !== 0
                ? [
                    ...this.cookPrintForm.goodsItem,
                    ...this.cookPrintForm.goodsBrand,
                    ...this.cookPrintForm.goodsCategory
                  ]?.map?.(({ lineId, unitId, lineType }) => ({
                    lineId,
                    unitId,
                    lineType
                  }))
                : [];

            const apiMethod = isUpdate ? updateCase : saveCase;
            const cookPrintForm = JSON.parse(
              JSON.stringify(this.cookPrintForm)
            );
            delete cookPrintForm.goodsItem;
            delete cookPrintForm.goodsBrand;
            delete cookPrintForm.goodsCategory;
            await apiMethod(cookPrintForm);
            // 重新赋值给表单并提示
            let msg = isUpdate ? "修改方案成功" : "新增方案成功";
            this.$modal.msgSuccess(msg);
            this.$router.push("/decoration/base/goodsCase");
            try {
              this.resetForm("cookPrintForm");
            } catch (error) {}
          } catch (error) {
            console.log("error----", error);
          }
        }
      });
    },
    //获取选中的数据赋值表格    type区分类型(门店/商品)
    getPitchData(data, type) {
      let tableData = []; //定义一个数组变量 (存放不同表格)
      let id; //定义一个变量存放不同id名称
      if (type === "shop") {
        //门店数据
        tableData = this.cookPrintForm.decorationCaseShopReqs; //使用引入类型 数组是栈类型 直接赋值栈的地址不变
        id = "shopId"; //门店id名称
      } else if (
        //(商品/商品类别/商品品牌)
        type === "goodsItem" ||
        type === "goodsCategory" ||
        type === "goodsBrand"
      ) {
        //商品数据
        tableData = this.cookPrintForm[type];
        id = "lineId"; //(商品/商品类别/商品品牌)公用id
        // console.log("======", this.id);  只是赋值，打印不到数据
      }
      if (tableData.length >= 1) {
        //当表格数据存在时
        for (let i = 0; tableData.length > i; i++) {
          data = data.filter(v => {
            //商品表格/会员表格需要添加上类型
            if (type === "goodsItem") {
              //商品
              v.lineType = 1; //商品表格行统一类型
              v.lineId = v.goodsId; //商品表格行统一id
              v.lineNo = v.goodsNo; //统一编号字段
              v.lineName = v.goodsName; //统一名称字段
            } else if (type === "goodsCategory") {
              //商品类别
              v.lineType = 3;
              v.lineNo = v.categoryNo; //字段转换成商品表格一样的字段
              v.lineName = v.categoryName;
              v.lineId = v.categoryId;
            } else if (type === "goodsBrand") {
              //商品品牌
              v.lineType = 2;
              v.lineNo = v.brandNo; //字段转换成商品表格一样的字段
              v.lineName = v.brandName;
              v.lineId = v.brandId;
            }
            return v[id] !== tableData[i][id]; //选中的数据(门店/商品/会员) 过滤掉已经在表格中已经存在的数据
          });
        }
        tableData.push(...data); //再追加在表格里
      } else {
        // console.log("dap", data);
        //当表格数据为空时,直接追加表格里
        data.forEach(v => {
          //商品表格/会员表格需要添加上类型
          if (type === "goodsItem") {
            //商品
            v.lineType = 1; //商品表格行统一类型
            v.lineId = v.goodsId; //商品表格行统一id
            v.lineNo = v.goodsNo; //统一编号字段
            v.lineName = v.goodsName; //统一名称字段
          } else if (type === "goodsCategory") {
            //商品类别
            v.lineType = 3;
            v.lineNo = v.categoryNo; //字段转换成商品表格一样的字段
            v.lineName = v.categoryName;
            v.lineId = v.categoryId;
          } else if (type === "goodsBrand") {
            //商品品牌
            v.lineType = 2;
            v.lineNo = v.brandNo; //字段转换成商品表格一样的字段
            v.lineName = v.brandName;
            v.lineId = v.brandId;
          }
          tableData.push(v);
        });
      }
    },
    //删除表格行事件(共同删除事件,以type区分表格(门店/商品))
    delTableRow(row, type) {
      if (type === "shop") {
        //门店
        //过滤掉删除的数据行
        this.cookPrintForm.decorationCaseShopReqs = this.cookPrintForm.decorationCaseShopReqs.filter(
          v => v.shopId !== row.shopId
        );
      } else if (type === "goods") {
        //商品
        //过滤掉删除的数据行
        this.cookPrintForm.goodsItem = this.cookPrintForm.goodsItem.filter(
          v => v.lineId !== row.lineId
        );
        this.cookPrintForm.goodsBrand = this.cookPrintForm.goodsBrand.filter(
          v => v.lineId !== row.lineId
        );
        this.cookPrintForm.goodsCategory = this.cookPrintForm.goodsCategory.filter(
          v => v.lineId !== row.lineId
        );
      }
    },
    // 表单重置
    reset() {
      this.cookPrintForm = {
        decorationCaseId:'',
        decorationCaseName: "", //方案名称
        decorationCaseNo: "", //方案编号
        shopRangeType: 0, //适用门店
        goodsRangeType: 0, //适用商品
        decorationCaseGoodsReqs: [], //商品方案适用商品新增对象 //行类型(1:商品;2:品牌;3:类别)
        decorationCaseShopReqs: [], // 门店适用范围
        isStop: 1,
        goodsCategory: [],
        goodsBrand: [],
        goodsItem: []
      };
      try {
        this.resetForm("cookPrintForm");
      } catch (error) {}
    },
    //删除门店内容
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      );
      this.$destroy();
      this.$router.push("/produce/decoration/goodsCase");
      try {
        this.resetForm("cookPrintForm");
      } catch (error) {}
    },

    //导入选中商品类别
    categoryList(value) {
      console.log("选中？", value);
      this.loadingBrand = true;
      if (this.cookPrintForm.goodsItem != []) {
        this.cookPrintForm.goodsItem = this.cookPrintForm.goodsItem.filter(
          item =>
            !(item.goodsId === undefined) ||
            !(item.brandId === undefined) ||
            !(item.categoryId === undefined)
        );
      }
      value.forEach(v => {
        //声明属性
        this.$set(v, "lineType", 3);
        this.$set(v, "lineId", v.categoryId);
        this.$set(v, "lineName", v.categoryName);
        this.$set(v, "lineNo", v.categoryNo);
      });
      for (
        let index = 0;
        index < this.cookPrintForm.goodsItem.length;
        index++
      ) {
        value = value.filter(v => {
          if (this.cookPrintForm.goodsItem[index].lineType === 3) {
            return (
              v.categoryId != this.cookPrintForm.goodsItem[index].categoryId
            );
          } else {
            return v;
          }
        });
      }

      this.cookPrintForm.goodsItem.push(...value);
      this.loadingBrand = false;
    },
    //导入选中品牌
    brandList(value) {
      this.loadingBrand = true;
      if (this.cookPrintForm.goodsItem != []) {
        this.cookPrintForm.goodsItem = this.cookPrintForm.goodsItem.filter(
          item =>
            !(item.goodsId === undefined) ||
            !(item.brandId === undefined) ||
            !(item.categoryId === undefined)
        );
      }
      value.forEach(v => {
        //声明属性
        this.$set(v, "lineType", 2);
        this.$set(v, "lineId", v.brandId);
        this.$set(v, "lineName", v.brandName);
        this.$set(v, "lineNo", v.brandNo);
      });

      for (
        let index = 0;
        index < this.cookPrintForm.goodsItem.length;
        index++
      ) {
        value = value.filter(v => {
          if (this.cookPrintForm.goodsItem[index].lineType === 2) {
            return v.brandId != this.cookPrintForm.goodsItem[index].brandId;
          } else {
            return v;
          }
        });
      }

      this.cookPrintForm.goodsItem.push(...value);
      this.loadingBrand = false;
    },

    //打开商品类别
    getOpenCategory() {
      this.OpenCategory = true;
    },
    //打开选择品牌
    getOpenBrand() {
      this.OpenBrand = true;
    },
    // 打开选择门店对话框
    openSelectShop() {
      this.OpenShop = true;
    },
    //打开选择商品
    getGoodsFrame() {
      this.OpenGoods = true;
    },
    //删除商品表格的内容
    deleteRowGoods(index, rows) {
      rows.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 6px 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //内容
  .tableContent {
    width: 100%;
    padding: 6px 10px 15px 10px;
  }
  //图片管理
  .tableContentImg {
    padding: 20px 0 10px 30px;
  }
  //输入框
  ::v-deep .el-input--medium .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
  ::v-deep .el-form-item {
    margin: 0;
    padding: 0;
  }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .basicInfoBottom {
    padding: 10px 0;
    //每一行的间距
    .el-form-item {
      margin-bottom: 10px;
    }
  }
}
</style>
