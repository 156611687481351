var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "裱花APP开通" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "head" }, [
              _c("div", {
                staticClass: "head-logo",
                style:
                  "background-image:url(" +
                  require("@/assets/images/bh-log.png") +
                  ");",
              }),
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "14px", "line-height": "28px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("裱花间APP")]
                    ),
                    _c("div", [
                      _vm._v(
                        " 裱花间 APP是一款专为中央裱花间/工厂设计的智能管理系统，能够实时监控不同类型的业务订单的接单、生产、配送和签收过程。 "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "div",
                      { staticClass: "button", on: { click: _vm.goBuy } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.price) +
                            " " +
                            _vm._s(
                              _vm.isBuy == "buy" ? "立即开通" : "立即续费"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-link",
                      {
                        staticClass: "text-button",
                        attrs: { type: "primary" },
                        on: { click: _vm.goList },
                      },
                      [_vm._v("查询订单列表>")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "head cardContent2 el-card is-always-shadow" }, [
        _vm._m(0),
        _c("div", {
          staticClass: "logo2",
          style:
            "background-image:url(" +
            require("@/assets/images/bh-panel.png") +
            ");",
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-view" }, [
      _c(
        "div",
        { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
        [_vm._v("产品介绍")]
      ),
      _c("div", { staticClass: "text-p" }, [
        _vm._v(
          " 随着电商的发展和门店成本的上升，烘焙连锁企业开始采用中央裱花间模式，提高效率和 降低人力成本。裱花间 APP是一款专为中央裱花间/工厂设计的智能管理系统，能够实时监控 不同类型的业务订单的接单、生产、配送和签收过程。 "
        ),
      ]),
      _c("div", { staticClass: "text-p" }, [
        _vm._v(
          " 通过裱花间 APP，烘焙连锁企业可以实现裱花制作集中化、信息化，提升客户满意度。 客户下单后，订单信息自动传送到裱花间平板并打印小票，裱花师根据订单信息及图片样式 制作蛋糕，订单进度以及最新状态可实时同步到门店及C端用户，增强数据共享和协同优化。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }